.mobileGrid {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin-left: 5%;
  font-size: 10px;
  padding-top: 70px;
  gap: 20px 20px;
}

.grid {
  display: flex;
  position: absolute;
  width: 89.6vw;
  flex-wrap: wrap;
  font-family: 'Arial Narrow', Arial, sans-serif, Helvetica, sans-serif;
  font-size: 13px;
  padding-top: 100px;
  left: 2.7vw;
}

.grid .footer {
  font-size: 13px;
}

.p50 {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  width: 44.8vw;
}

.footer {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 20px;
  margin-left: 2.7vw;
}

.footer a {
  text-decoration: none;
}

.posFixed {
  position: fixed;
}


.blurredBackground {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(12px);
  background-color: rgba(255,255,255, 0.5);
  top: 0;
  position: fixed;
  z-index: 1;
}

.closeButton {
  all: unset;
  color: #000;
  font-size: 70px;
  position: fixed;
  z-index: 11;
  right: 30px;
  top: 0;
}

.projectTitle{
  font-size: 19px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 20px;
}

.detailedProject {
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  position: fixed;
  z-index: 10;
  gap: 20px;
  overflow-y: scroll;
}

.copiedMedia {
  width: 55%;
  z-index: 11;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.aboutMedia .verticalCopy{
  height: 60%;
}

.carousel {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.horizontalCopy {
  width: 70%;
  margin: 0 auto;
  transition: 1s all;
}

.verticalCopy {
  height: 70%;
  margin: 0 auto;
  transition: 1s all;
}

.projectDescription{
  width: 30%;
  transition: 1s all;
  margin: auto 0;
  padding-right: 50px;
  font-size: 19px;
  white-space: pre-wrap;
  overflow-wrap: normal;
}

.projectDescription a{
  color: black;
}

.play {
  cursor: pointer;
  margin-left: calc(50% - 20px);
  width: 40px;
  margin-top: 20px;
  text-align: center;
}


.muteButton {
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 30px;
  text-transform: uppercase;
  font-size: 10px;
}

@media (max-width: 850px) {
  .detailedProject {
    flex-direction: column;
  }

  .copiedMedia {
    width: 100%;
  }

  .aboutMedia .verticalCopy {
    width: calc(100% - 30px);
    height: auto;
    padding: 0 15px;
    margin-top: 75px;
  }

  .verticalCopy {
    height: auto;
    width: calc(100% - 30px);
    padding: 0 15px;
    margin-top: 75px;
  }

  .horizontalCopy {
    width: calc(100% - 30px);
    height: auto;
    padding: 0 15px;
    margin-top: 75px;
  }

  .projectDescription {
    width: calc(100% - 30px);
    padding: 0 15px;
    margin-bottom: 20px;
  }

  .muteButton {
    bottom: auto;
    left: 15px;
    top: 60px;
  }


}