.carousel {
  height: 80vh;
  width: 40vw;
  position: relative;
  display: flex;
}

.carouselTrackContainer {
  width: 100%;
  position: relative;
  height: 100%;
}

.carouselTrack {
  width: 100%;
  height: 100%;
  list-style: none;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
}

.carouselTrack img, .carouselTrack video {
  max-width: 90%;
  max-height: 100%;
  object-fit: contain;
}

.carouselSlide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carouselButton {
  border: none;
  background: none;
  position: absolute;
  z-index: 10;
  height: 100%;
  font-size: 26px;
  width: 30px;
  color: black;
}

.carouselButton:last-child {
  right: 0px
}

@media (max-width: 850px) {
  .carousel {
    width: calc(100%);
    height: 70vh;
    margin-top: 80px;
  }
}