.project {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-bottom: 5.4vw;
  margin-left: 5.4vw;
  position: relative;
}

.project span {
    font-family: 'Arial Narrow', Arial, sans-serif, Helvetica, sans-serif;
    text-transform: uppercase;
    white-space: pre-wrap;
}

.project.small {
  width: 17vw;
  height: 21.4vw;
}

.project.large {
  width: 39.4vw;
  height: 31.52vw;
}

.project video, .project img {
  height: 80%;
  margin: auto auto;
}

.horizontalMedia, .verticalMedia {
  margin: auto auto;
}

.horizontalMedia {
  max-width: 90%;
  height: auto;
  object-fit: contain;
}

.verticalMedia {
  height: 80%;
  width: auto;
}

.project span {
  width: 100%;
  margin-top: auto;
  text-transform: uppercase;
  border-top: 0;
}


@media (max-width: 850px) {
  .project {
    margin-bottom: 0;
    margin-left: 0;
  }

  .project.small {
    width: calc(50% - 10px);
    height: 55vw;
  }

  .project.large {
    width: 100%;
    height: 72vw;
  }

  .project span {
    white-space: pre-wrap;
  }
}