*{
  margin: 0;
  padding: 0;
}

.casePage {
  width: 80%;
  margin: 0 auto;
  margin-top: 70px;
  display: flex;
  gap: 80px;
}

.projectDescription {
  display: flex;
  flex-direction: column;
  width: 62%;
  justify-content: center;
  height: calc(100vh - 100px);
  overflow: scroll;
}

.projectDescription h1{
  text-transform: capitalize;
  font-size: 24px;
  margin: 0;
  padding: 0;
}

.projectDescription p {
  margin-top: 20px;
  white-space: pre-wrap;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  overflow-y: scroll;
}

.images {
  width: 38%;
  height: calc(100vh - 110px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}


.images img, video {
  width: 100%;
  margin-bottom: 10px;
}

.mute {
  cursor: pointer;
  position: fixed;
  top: 50px;
  z-index: 2000;
  height: 15px;
  text-transform: uppercase;
}