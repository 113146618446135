.navbar {
  text-align: center;
  display: flex;
  font-family: 'Arial Narrow', Arial, sans-serif, Helvetica, sans-serif;
  text-transform: uppercase;
  height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 200;
}

.navbar a {
  text-decoration: none;
}

.navbar h1{
  font-size: 30px;
  margin: auto auto;
  left: 0;
  right: 0;
  font-weight: normal;
}


.links {
  font-size: 13px;
  position: absolute;
  display: flex;
  position: absolute;
  right: 30px;
  top: 20px;
}

.links a {
  text-decoration: none;
  color: black;
}


